.listGrid-Head {
    display: flex;
    padding: 10px 10px;
    justify-content: space-between;
    background-color: #d9d9d9;
    font-size: 20px;
    font-weight: 600;
}

html[dir="rtl"] .listGrid-Head {
    font-size: 22px;
}

.listGrid-arrow {
    background-color: #efefef;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin: -10px 0;
    font-size: 22px;
}

html[dir="rtl"] .listGrid-arrow {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}

.subcatgeory-mainContainer {
    text-decoration: none;
    font-size: 0.875rem;
    word-wrap: break-word;
}

.subcategory-link {
    color: black;
}

.subcatgeory-mainContainer .category-image {
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    margin: 0 auto 8px;
    margin-bottom: 8px;
    display: block;
    height: 216px;
    -o-object-fit: cover;
    object-fit: cover;
    max-width: 100%;
    /* width:100%; */
    width: auto;
}

.subcatgeory-mainContainer .category-title {
    font-size: 17px;
    text-align: center;
    font-weight: 700;
    line-height: 1.41;
    color: black;
    overflow-wrap: break-word;
    word-break: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 0;
}

html[dir="rtl"] .subcatgeory-mainContainer .category-title {
    font-size: 19px;
}

@media (max-width: 576px) {
    .subcatgeory-mainContainer .category-title {
        font-size: 16px;
    }
}