:root {
  --vendor-color: black;
  --spinner-color: #fff;
  --spinner-height: 16px;
  --spinner-size: 2.5px;
  --spinner-margin: 0px;
}
.lds-ring {
  display: inline-block;
  position: relative;
  width: calc(var(--spinner-height) + 1px);
  height: calc(var(--spinner-height) + 1px);
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: var(--spinner-height);
  height: var(--spinner-height);
  margin: var(--spinner-margin);
  border: var(--spinner-size) solid var(--spinner-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--spinner-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
