:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.newOrderMode-mainDiv {
  padding: 16px 29px;
}

.modeSelector-mainDiv {
  display: flex;
  /* background-color: #f1f1f1; */
  background-color: #f4f4f4;
  gap: 4px;
  border-radius: 12px;
  padding: 4px;
}

.modeSelector-tabs {
  width: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

html[dir="rtl"] .modeSelector-tabs {
  font-size: 18px;
}

.modeSelector-active {
  color: #fff;
  width: 116px;
  background: linear-gradient(133deg,
      var(--vendor-color) 0%,
      var(--vendor-color) 0%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.09),
    0px -3px 5px 0px rgba(241, 241, 241, 0.2) inset;
  transition: background 0.3s, transform 0.1s;
}

.modeSelector-insidetabs {
  display: flex;
  cursor: pointer;
  padding: 7px 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 8px;
}

.modeSelector-insidetabs:active {
  background: linear-gradient(133deg,
      var(--vendor-color) 0%,
      var(--vendor-color) 0%);
  transform: translateY(1px);
}

.delivery-areas-mainDiv {
  padding: 20px 0;
}

.delivery-area-search {
  border-radius: 12px;
  background: #f1f1f1;
  width: 100%;
  padding: 4px 14px;
  display: flex;
  justify-content: space-around;
  gap: 11px;
  align-items: center;
  height: 33px;
  overflow: hidden;
}

.delivery-area-search-input {
  resize: none;
  display: block;
  width: 100%;
  color: black;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.33;
  letter-spacing: -0.2px;
  border: none;
  background: #f1f1f1;
  padding: 0 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.delivery-area-search-input:focus {
  border: none;
  outline: none;
}

.delivery-area-governarate {
  margin-top: 20px;
}

.delivery-area-governarate-holder {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
  background-color: #fff;
  height: 48px;
  cursor: pointer;
  border-bottom: 0.5px solid #d9d9d9;
  color: #000000;
}

.delivery-area-governarate-holder:active {
  transform: translateY(1px);
}

.delivery-area-governarate-header {
  text-align: start;
  outline: none;
  padding: 0;
  border: none;
  background-color: #fff;
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #000000;
}

html[dir="rtl"] .delivery-area-governarate-header {
  font-size: 18px;
}

.delivery-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
}

html[dir="rtl"] .delivery-arrow {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.delivery-area-collapse {
  padding: 14px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  display: none;
}

.delivery-area-collapse.active {
  display: block;
}

.delivery-area-collapse ul {
  width: 100%;
  padding-left: 0;
}

html[dir="rtl"] .delivery-area-collapse ul {
  padding-right: 0;
}

.delivery-area-collapse ul li {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 14px 20px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-bottom: 0.5px solid #d9d9d9;
}

html[dir="rtl"] .delivery-area-collapse ul li {
  font-size: 18px;
}

.delivery-area-collapse ul li .branch-avai-status {
  color: #ce1414;
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

html[dir="rtl"] .delivery-area-collapse ul li .branch-avai-status {
  font-size: 14px;
}

.modeSelector-mainDivNew {
  background-color: #f4f4f4;
  gap: 4px;
  border-radius: 12px;
  padding: 4px;
}

.modeSelector-tabsNew {
  position: relative;
}

.modeSelector-tabsNew:after {
  content: "";
  position: absolute;
  width: 50%;
  top: 0;
  transition: left cubic-bezier(.88, -.35, .565, 1.35) .4s;
  border-radius: 8px;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .1);
  background-color: var(--vendor-color);
  height: 100%;
  z-index: 0;
}

.modeSelector-tabsNew.left:after,
html[dir="rtl"] .modeSelector-tabsNew.right:after {
  left: 0;
}

.modeSelector-tabsNew.right:after,
html[dir="rtl"] .modeSelector-tabsNew.left:after {
  left: 50%;
}

.modeSelector-insidetabsNew {
  display: inline-block;
  width: 50%;
  z-index: 1;
  position: relative;
  cursor: pointer;
  transition: color 500ms;
  font-weight: bold;
  line-height: normal;
  user-select: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 7px 8px;
}

html[dir="rtl"] .modeSelector-insidetabsNew {
  font-size: 18px;
}

.modeSelector-tabsNew .modeSelector-insidetabsNew.active {
  color: #ffffff;
}

.modeSelector-tabsNew.selector-full .modeSelector-insidetabsNew {
  width: 100%;
}

.modeSelector-tabsNew.selector-full:after {
  left: 0;
}

.selector-full.modeSelector-tabsNew:after {
  width: 100%;
}