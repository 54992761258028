:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.cart-card-product-div {
  display: flex;
  align-items: center;
  min-height: 110px;
  padding: 10px 0;
  cursor: pointer;
}

.cart-card-product-mainDiv {
  display: grid;
  width: 100%;
  gap: 20px;
  grid-template-columns: 70px auto;
  padding: 0 10px 10px;
  align-items: center;
}

.cart-cart-product-name {
  min-height: 30px;
  display: flex;
  font-weight: 600;
  align-items: end;
  vertical-align: middle;
  font-size: 16px;
}

html[dir="rtl"] .cart-cart-product-name {
  font-size: 20px;
}

.cart-cart-product-offer {
  font-size: 11px;
  height: 20px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  color: #ce1414;
}

html[dir="rtl"] .cart-cart-product-offer,
html[dir="rtl"] .cart-cart-product-notes {
  font-size: 15px;
}

.cart-cart-product-notes {
  font-size: 11px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #a3a2a2;
}

.cart-cart-product-quantity-div {
  display: flex;
  justify-content: space-between;
  align-items: start;
  min-height: 20px;
  padding-top: 5px;
}

.cart-card-price-maindiv {
  min-height: 30px;
  text-align: end;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  align-items: end;
}

.cart-card-price-div {
  display: flex;
  gap: 5px;
  width: 17px;
  text-align: center;
  justify-content: center;
}

.cart-card-product-quantity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  gap: 10px;
  cursor: pointer;
}

html[dir="rtl"] .cart-card-product-quantity {
  direction: ltr;
}

.cart-card-quantity-button {
  border-radius: 20px;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  font-weight: 600;
  font-size: 16px;
  padding-left: 1px;
  padding-bottom: 1px;
}

html[dir="rtl"] .cart-card-quantity-button {
  /* align-items: flex-end; */
  align-items: center;
  font-size: 20px;
  padding-bottom: 5px;
}

.cart-card-price-div {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

html[dir="rtl"] .cart-card-price-div {
  font-size: 20px;
}

.buyer-details-mainDIv {
  display: grid;
  grid-template-columns: 80% 20%;
  padding: 28px 0;
  margin: 32px 0;
  border-bottom: 0.25px solid #ccc;
  border-top: 0.25px solid #ccc;
}

.buyer-details-secondDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.buyer-details-secondDiv .right-arrow {
  font-size: 27px;
  color: #000;
}

html[dir="rtl"] .buyer-details-secondDiv .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.delivery-company-mainDiv {
  display: grid;
  grid-template-columns: 50px auto;
  align-items: center;
  gap: 20px;
  margin-top: 28px;
  padding-top: 28px;
  border-top: 0.25px solid #ccc;
}

.delivery-company-secondDiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delivery-company-delivery-by-text {
  font-weight: 500;
  color: #837f7f;
  display: flex;
  align-items: center;
  height: 20px;
  font-size: 14px;
}

.delivery-company-delivery-name {
  font-weight: 600;
  height: 18px;
  font-size: 20px;
  justify-content: start;
  display: flex;
  align-items: center;
}

.delivery-company-delivery-time {
  font-weight: 500;
  height: 18px;
  font-size: 14px;
  justify-content: end;
  display: flex;
  align-items: center;
}

html[dir="rtl"] .delivery-company-delivery-by-text {
  font-size: 18px;
}

html[dir="rtl"] .delivery-company-delivery-name,
html[dir="rtl"] .delivery-company-delivery-time {
  font-size: 18px;
  font-weight: 700;
}

.delivery-company-imageDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.delivery-company-delivery-text {
  font-size: 16px;
}

html[dir="rtl"] .delivery-company-delivery-text {
  font-size: 20px;
}

.payment-details-mainDiv {
  position: relative;
  padding: 28px 0;
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.payment-details-payment-type {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  cursor: pointer;
}

.payment-details-holder {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}

.payment-details-holder.active {
  border: 2.5px solid #000;
}

.payment-details-holder .del-ic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 57px;
  vertical-align: middle;
  width: 84px;
}

.delivery-payment-slider {
  display: inline-block;
  position: absolute;
  max-width: 130px;
  min-width: 115px;
  width: 20%;
  height: 74px;
  background-color: #7bf7ff45;
  border-radius: 5px;
  transition: all 0.2s linear;
  bottom: 0px;
}

.promocode-text {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  vertical-align: middle;
  align-items: center;
}

.promocode-text:hover {
  color: #000;
}

html[dir="rtl"] .promocode-text {
  font-size: 24px;
}

.promocode-text .right-arrow {
  font-size: 27px;
  color: #000;
}

html[dir="rtl"] .promocode-text .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  transition: 0.3s ease-in-out;
}

html[dir="rtl"] .promocode-text.collapsed .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.promocode-text .right-arrow {
  transform: rotate(90deg);
  transition: 0.3s ease-in-out;
}

.promocode-text.collapsed .right-arrow {
  transform: rotate(0deg);
  transition: 0.3s ease-in-out;
}

.promocode-input-div {
  padding: 12px 0px;
  vertical-align: middle;
  display: grid;
  align-items: center;
  grid-template-columns: auto 72px;
  gap: 10px;
}

.promocode-input-div .apply-button {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  background-color: var(--vendor-color);
  border: 1px solid var(--vendor-color);
  color: #fff;
  border-radius: 10px;
  height: 43px;
}

.promocode-input-div .apply-button:hover {
  background-color: var(--vendor-color-light);
}

.promocode-input-div .apply-button:active {
  transform: translateY(1px);
}

.amount-discount {
  font-size: 14px;
  color: #d70404;
  font-weight: 600;
}

html[dir="rtl"] .amount-discount {
  font-size: 18px;
}

.amount-sub-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  vertical-align: middle;
  padding-top: 10px;
}

.amount-sub-label {
  font-size: 14px;
  font-weight: 600;
}

.amount-sub-label-total {
  font-size: 16px;
  font-weight: 700;
}

html[dir="rtl"] .amount-sub-label {
  font-size: 18px;
}

html[dir="rtl"] .amount-sub-label-total {
  font-size: 20px;
}

.amount-sub-amount {
  font-size: 14px;
  font-weight: 600;
  display: flex;
  gap: 10px;
}

.amount-sub-amount-total {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  gap: 10px;
}

html[dir="rtl"] .amount-sub-amount-total {
  font-size: 20px;
}

html[dir="rtl"] .amount-sub-amount {
  font-size: 20px;
}

.pay-now-button {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px;
  vertical-align: middle;
  color: #fff;
  background-color: var(--vendor-color);
  font-size: 20px;
  outline: none;
  border: 1px solid var(--vendor-color);
  font-weight: 600;
  border-radius: 87px;
  cursor: pointer;
}

.pay-now-button {
  transform: translateY(1px);
}

html[dir="rtl"] .pay-now-button {
  font-size: 24px;
}

.product-detail-image {
  height: 70px;
  width: 70px;
  border-radius: 10px;
}

.buyer-details-firstDiv div.buyer-details-name {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  color: #000;
  height: 30px;
}

html[dir="rtl"] .buyer-details-firstDiv div.buyer-details-name {
  font-size: 24px;
  min-height: 40px;
}

.buyer-phone-number {
  display: flex;
  vertical-align: middle;
  align-items: center;
  font-size: 14px;
  color: #837f7f;
  height: 23px;
}

.dontchane-direction {
  display: flex;
  align-items: center;
}

html[dir="rtl"] .dontchane-direction {
  direction: ltr;
}

html[dir="rtl"] .buyer-phone-number {
  font-size: 14px;
}

@media (max-width: 600px) {
  .divider-class {
    margin: 10px 0;
    margin-bottom: -10px;
  }
  .fourbuttons {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    justify-content: space-around;
  }
  .fourbuttons a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.promocode-input-div1 {
  position: relative;
}

.promocode-input-div1 .form-control {
  background-color: #f1f1f1;
  border: 0.25px solid #e6e6e6;
  height: 43px;
}

.fashion-theme .promocode-input-div1,
.fashion-theme .apply-button {
  border-radius: 0px;
}

.fashion-theme-pay-button {
  border-radius: 0;
}

.delivery-addres-heading-checkout {
  font-size: 20px;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

html[dir="rtl"] .delivery-addres-heading-checkout,
html[dir="rtl"] .delivery-payment-details-heading {
  font-size: 24px;
}

.delivery-payment-details-heading {
  font-size: 20px;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.delivery-address-details-mainDIv {
  display: grid;
  grid-template-columns: calc(95% - 20px) 5%;
  gap: 20px;
}

.delivery-address-details-firstDiv div {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  vertical-align: middle;
  align-items: center;
  color: #837f7f;
  min-height: 24px;
}

html[dir="rtl"] .delivery-address-details-firstDiv div {
  font-size: 18px;
}

.delivery-address-details-secondDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.delivery-address-details-secondDiv .right-arrow {
  font-size: 27px;
  color: #000;
}

html[dir="rtl"] .delivery-address-details-secondDiv .right-arrow {
  margin-right: 20px;
  margin-left: 5px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.promocode-mainDiv {
  border-top: 0.25px solid #ccc;
  padding-top: 28px;
}

.payment-details-headDIv,
.acmount-mainDIv {
  margin-top: 28px;
  border-top: 0.25px solid #ccc;
  padding-top: 28px;
}

.order-details-cart-disocunt {
  font-size: 10px;
  text-decoration: line-through;
  color: rgb(151, 151, 151);
  font-weight: 500;
}

.payby-payzah-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 8px;
  background: #361f98;
  gap: 3px;
  height: 35px;
}

.payby-text {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.payzah-text {
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 706;
  line-height: normal;
}

html[dir="rtl"] .payby-text,
html[dir="rtl"] .payzah-text {
  font-size: 14px;
}

.payby-image {
  width: 15.561px;
  height: 22px;
}

.loader-class {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.promocode-cross-button {
  position: absolute;
  right: 10px;
  top: 8px;
  cursor: pointer;
}

html[dir="rtl"] .promocode-cross-button {
  left: 10px;
  right: auto;
}

.delivery-not-serving {
  display: flex;
  padding-bottom: 20px;
  padding-top: 10px;
  justify-content: center;
  align-items: center;
}

.delivery-not-serving button {
  padding: 10px;
  border: 2px solid var(--vendor-color-light);
  font-size: 16px;
  border-radius: 20px;
}

.delivery-not-serving button:hover {
  background-color: var(--vendor-color-light);
  color: #fff;
  cursor: pointer;
}

.showAppInstall {
  height: 55px;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 999;
  animation: slideInTOP 1s ease-in;
}

@keyframes slideInTOP {
  0% {
    top: -55px;
  }

  100% {
    top: 0px;
  }
}

.hideAppInstall {
  position: fixed;
  top: -55px;
  overflow: hidden;
  z-index: 999;
  animation: slideouTOp 1s ease-in;
}

@keyframes slideouTOp {
  0% {
    top: 0px;
  }

  100% {
    top: -50px;
  }
}

.delivery-alignment {
  padding-left: 25px;
}

html[dir="rtl"] .delivery-alignment {
  padding-left: auto;
  padding-right: 25px;
}

.outOfStock-mainDiv {
  display: flex;
  padding: 10px;
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  color: black;
  padding-bottom: 20%;
  color: #f00;
}

.outOfStock-mainDiv > div {
  background-color: #fde6e6;
  padding: 10px;
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
  border-radius: 10px;
}

html[dir="rtl"] .outOfStock-mainDiv {
  font-size: 18px;
}

.warning-icon {
  height: 25px;
  width: 25px;
  background-color: #ffbebe;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
}

.warning-text {
  font-weight: 500;
  line-height: normal;
  font-size: 14px;
  color: #f00;
}

html[dir="rtl"] .warning-text {
  font-size: 16px;
}
