:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.newCommon-navbar-mainDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--vendor-color);
  height: 53px;
  padding: 14px 14px 12px 18px;
}

.newCommon-navbar-hambuger {
  display: flex;
  padding: 8px 4px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.newCommon-navbar-hambuger:active {
  transform: translateY(1px);
}

.newCommon-navbar-hambuger div {
  width: 17px;
  height: 2px;
  background: #fff;
  cursor: pointer;
}

.newCommon-navbar-langugaeGroup {
  display: flex;
  user-select: none;
  gap: 16px;
  align-items: flex-start;
  justify-content: flex-end;
}

html[dir="rtl"] .newCommon-navbar-langugaeGroup {
  align-items: center;
}

.newCommon-navbar-language-name {
  color: #fff;
  font-family: "AtypeBelqeesSharp";
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: pointer;
}

.newCommon-navbar-language-name:active {
  transform: translateY(1px);
}

html[dir="rtl"] .newCommon-navbar-language-name {
  font-family: var(--vendor-font-family);
}

.kuwaitFlag {
  width: 25.658px;
  height: 25.953px;
  user-select: none;
  background-color: var(--vendor-color);
  border-radius: 50%;
  border: 2px solid lightgray;
  margin: 0px 10px;
}

.newCommon-navbar-downArrow {
  cursor: pointer;
  position: relative;
}

.newCommon-navbar-options {
  position: absolute;
  z-index: 1001;
  right: 5px;
  top: 35px;
  padding: 0 8px;
  width: 100px;
  background-color: var(--vendor-color);
  color: #fff;
  border: 2px solid lightgray;
}

html[dir="rtl"] .newCommon-navbar-options {
  right: auto;
  left: 5px;
}

.newCommon-navbar-options-arabic {
  padding: 10px 0;
  font-family: "AtypeBelqeesSharp";
}

.newCommon-navbar-options-english {
  font-family: var(--vendor-font-family) !important;
  padding: 10px 0;
  border-bottom: 2px solid lightgray;
}

.newCommon-navbar-downArrow-rotate {
  display: flex;
  align-items: center;
  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;
}

.newCommon-navbar-downArrow-unrotate {
  display: flex;
  align-items: center;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}