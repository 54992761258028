@font-face {
  font-family: "SFT Schrifted Sans TRIAL Var";
  src: url("./SFT-font/SFTSchriftedSansTRIAL-Bold-BF6413e20329194.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SFT Schrifted Sans TRIAL Var";
  src: url("./SFT-font/SFTSchriftedSansTRIAL-DemiBold-BF6413e1ee404fe.otf")
    format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "SFT Schrifted Sans TRIAL Var";
  src: url("./SFT-font/SFTSchriftedSansTRIAL-ExLightItComp-BF6413e1edb3a54.otf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "SFT Schrifted Sans TRIAL Var";
  src: url("./SFT-font/SFTSchriftedSansTRIAL-ExtraLight-BF6413e1ed0f558.otf")
    format("opentype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "SFT Schrifted Sans TRIAL Var";
  src: url("./SFT-font/SFTSchriftedSansTRIAL-Medium-BF6413e2024d36d.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}
