.vendorDesktop-main {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.vendorDesktop-img {
    width: 7rem;
    height: 100%;
    margin: 0 10px;
    border-radius: 10px;
}

.vendorDesktop-name {
    font-weight: 600;
    font-size: 22px;
    text-align: center;
}

html[dir="rtl"] .vendorDesktop-name {
    font-size: 24px;
}

.vendorDesktop-slogan {
    font-size: 17px;
    color: darkgrey;
    line-height: 1.41;
    font-weight: 500;
    word-wrap: break-word;
    word-break: break-word;
    text-align: center;
}

html[dir="rtl"] .vendorDesktop-slogan {
    font-size: 21px;

}