:root {
  --vendor-color: black;
  --vendor-color-light: black;
  --vendor-hover-color: black;
  --vendor-font-family: "Open Sans", sans-serif;
}

.commonHeader-Div {
  width: 100%;
  font-weight: 700;
  height: 41px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01rem;
  height: 41px;
  font-size: 32px;
}

.commonSection-div {
  display: flex;
  align-items: center;
  color: #837f7f;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 15px;
}

.contact-details-label-name {
  font-size: 11px;
  color: #a3a2a2;
  padding: 0 7px;
  font-weight: 500;
  letter-spacing: 0;
  /* padding-bottom: 10px; */
}

.contact-details-label-name1 {
  position: absolute;
  top: 20px;
  transition: 0.2s ease all;
  user-select: none;
}

.contact-form-container input:focus~.contact-details-label-name1,
.contact-form-container input:not(:focus):valid~.contact-details-label-name1 {
  top: -5px;
  bottom: 0px;
  left: 0px;
}

html[dir="rtl"] .contact-form-container input:focus~.contact-details-label-name1,
html[dir="rtl"] .contact-form-container input:not(:focus):valid~.contact-details-label-name1 {
  top: -5px;
  bottom: 0px;
  right: 0px;
  left: auto;
}


.contact-details-flag {
  padding: 0;
}

.contact-details-flag button {
  border-bottom: none !important;
}

.contact-details-form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 7px;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  height: 45px;
}

.contact-details-form-control.contact-details-phone-number {
  font-family: var(--vendor-font-family);
}

html[dir="rtl"] .contact-details-form-control.contact-details-phone-number {
  font-family: var(--vendor-font-family);
}

input.contact-details-form-control:focus-visible {
  outline: none;
}

.contact-form-container {
  display: flex;
  gap: 10px;
  border-bottom: 0.25px solid #ccc;
}

.contact-details-form-padding {
  padding: 0 7px;
  border-bottom: 0.25px solid #ccc;
}

.contact-details-form-div {
  margin-bottom: 20px;
  position: relative;
  /* min-height: 65px; */
}

.or-div {
  display: flex;
  gap: 12.46px;
  align-items: center;
  padding: 48px 30px;
  align-items: center;
  height: 22px;
  justify-content: center;
}

.or-line {
  width: 62.881px;
  height: 0px;
  border-bottom: 0.25px solid #ccc;
}

.or-text {
  /* font-weight: 605; */
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #000;
  /* color: #a3a2a2; */
  justify-content: center;
}

.contact-details-login-button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  color: #000;
}

.contact-details-login-button {
  height: 43px;
  background: #ffffff;
  border: 2px solid #000;
  border-radius: 24px;
  display: flex;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;

  width: 199px;
}

.contact-details-login-button:hover {
  background-color: var(--vendor-color);
  color: #fff;
}

.contact-details-bottom-button {
  position: absolute;
  bottom: 20px;
  height: 45px;
  width: 100%;
  left: 0;
  padding-left: 36px;
  padding-right: 36px;
  /* display: grid;
  grid-template-columns: 30% calc(70% - 12px); */
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.contact-details-next-button {
  border-radius: 40px;
  font-size: 16px;
  border: 1.5px solid var(--vendor-color);
  color: #fff;
  padding: 10px;
  display: flex;
  font-weight: 600;
  background-color: var(--vendor-color);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 45px;
  width: 100%;
  text-align: center;
  /* width: 206px; */
}

.contact-details-back-button {
  border-radius: 40px;
  height: 45px;
  color: var(--vendor-color);
  border: 2px solid var(--vendor-color);
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  /* width: 143px; */
  align-items: center;
}

.contact-details-next-button:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

.contact-details-next-button:active {
  transform: translateY(1px);
}


.contact-details-back-button:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

.contact-details-back-button:active {
  transform: translateY(1px);
}

.background-issue {
  background-color: #fff;
}

.background-issue:hover {
  background-color: var(--vendor-hover-color);
  color: #fff;
}

@media (max-width: 600px) {
  /* .or-div {
    padding: 20px;
  } */
  /* .full-screen-div {
    min-height: 100vh;
  } */
  /* .contact-details-form-div {
    padding-bottom: 10px;
  } */

  .contact-details-bottom-button {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.5);
    /* bottom: 69px; */
  }

  .contact-details-back-button {
    min-width: 100px;
  }

  /* .contact-details-next-button {
    min-width: 205px;
  } */
  .contact-details-label-name {
    padding-bottom: 0px;
  }

  .contact-details-mobile-button {
    position: fixed;
  }

  .review-order-mobile-button {
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 10px;
    height: auto !important;
  }

  .review-order-mobile-button .contact-details-back-button:hover {
    background-color: white;
    color: var(--vendor-color);
  }
}

.fashion-theme .contact-details-back-button {
  border-radius: 0px;
}

.fashion-theme .contact-details-next-button {
  border-radius: 0px;
}

.holder-container {
  padding: 26px 36px 69px 36px;
  min-height: calc(100vh - 53px);
  min-height: -webkit-fill-available;
  position: relative;
}

.fashion-theme-border {
  border-radius: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.commonback-maindiv {
  display: flex;
  margin: 16px 0;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  position: relative;
  min-height: 42px;
}

.commonback-holder {
  height: 42px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 9px 10px;
  cursor: pointer;
  position: absolute;
  left: 16px;
}

.commonback-holder-dark {
  background-color: rgba(255, 255, 255, 0.5);
}

.commonback-holder-light {
  background: rgba(73, 71, 71, 0.07);
}

html[dir="rtl"] .commonback-holder {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: auto;
  right: 16px;
}

.commonback-heading {
  color: #000;
  font-size: 18px;
  font-weight: 600;
}

html[dir="rtl"] .commonback-heading {
  font-size: 22px;
}

.sideview-fixed {
  position: fixed !important;
  width: auto;
  right: 0;
}

html[dir="rtl"] .sideview-fixed {
  /* position: fixed !important;
  width: auto; */
  left: 0;
  right: auto;
}

.product-details-backbutton {
  position: absolute;
  margin-top: 20px;
  z-index: 30;
  left: 16px;
}

html[dir="rtl"] .product-details-backbutton {
  right: 16px;
}

@media (max-width: 600px) {
  .product-details-backbutton {
    position: absolute;
    margin-top: 0px;
    left: 0px;
  }

}


@media (max-width:991px) {

  .contact-details-mobile-button {
    position: fixed;
  }

  .review-order-mobile-button {
    bottom: 0;
    padding-bottom: 20px;
    padding-top: 10px;
    height: auto !important;
  }
}

.commonback-holder:active {
  transform: translateY(1px);
}